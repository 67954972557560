<template>
  <v-container 
    class="pa-0"
    fluid
  >
  <v-row>
    <v-col cols="6">
      <container>
        <v-card-title class="primary--text">Tinc impressora 3D</v-card-title>
        <v-card-text>
          <p>
            Amb els paràmetres d'impressió de més avall nosaltres hem obtingut bons resultats, però depenent de la impressora la configuració pot variar lleugerament.
          </p>
          <p>
            Configuració recomanada:
          </p>
          <ul>
            <li>Alçada de capa: 0.2mm</li>
            <li>Gruix de paret: 2mm</li>
            <li>Densitat del relleu: 20%</li>
            <li>Material de suport: No</li>
          </ul>
        </v-card-text>
      </container>
    </v-col>
    <v-col cols="6">
      <container>
        <v-card-title class="primary--text">NO Tinc impressora 3D</v-card-title>
        <v-card-text>
          <p>
            Si no tens impressora 3D, nosaltres ho imprimim per tu! Només has de triar quina mida s'adapta millor a les teves necessitats i si tens alguna preferència de color:
          </p>
          <p class="title">
            Preu: {{price}}
          </p>
          <form>
            <v-select
              v-model="selectedSize"
              :items="sizeOptions"
              label="Seleccioneu la mida"
              required
              @change="sizeChanged"
            ></v-select>
            <v-select
              v-model="selectedColor"
              :items="colorOptions"
              label="Seleccioneu un material"
              @change="colorChanged"
            ></v-select>
          </form>
          <v-btn 
            class="primary"
            depressed
            large
          >
            Continuar amb la compra
            <v-icon>mdi-printer-3d-nozzle-outline</v-icon>
          </v-btn>
        </v-card-text>
      </container>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
import Container from '@/components/app/Container'
export default {
    name: 'PrintingTips',
    components: {
      Container
    },
    data: () => ({
      price: 'a partir de 10€',
      selectedSize: null,
      sizeOptions: [
        { value: 1, text: "10cm x 10cm x [0,2 - 1] cm"},
        { value: 2, text: "20cm x 20cm x [0,5 - 2] cm" },
        { value: 3, text: "30cm x 30cm x [1 - 3] cm" },
        //{ key: 4, name: "15cm x 15cm (gruix 0,5 - 1,5cm)" },
        //{ key: 5, name: "25cm x 25cm (gruix 0,5 - 2,5cm)" },
      ],
      selectedColor:null,
      colorOptions:[
        { value: 'none', text: "Indiferent" },
        { value: 'black', text: "Negre" },
        { value: 'white', text: "Blanc" },
        { value: 'grey', text: "Gris" },
        { value: 'deep_blue', text: "Blau marí" },
        { value: 'orange', text: "Taronja" },
        { value: 'red', text: "Vermell" },
        { value: 'green', text: "Verd" },
      ]
    }),
    methods: {
      sizeChanged() {
        switch (this.selectedSize){
          case 1:
            this.price = '10€';
            break;
          case 2:
            this.price = '17€';
            break;
          case 3:
            this.price = '25€';
            break;
        }
      },
      colorChanged() {
      }
    },
    props: {
    }
}
</script>
<style scoped>
</style>
