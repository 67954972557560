<template>
    <div>
        <v-app-bar
            ref="appBar"
            absolute
            color="white"
            elevate-on-scroll
            scroll-target="#page-content"
        >
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>{{title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <!--<v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>-->
        </v-app-bar>
        <v-sheet
            id="page-content"
            class="overflow-y-auto pa-0 mx-n3 mt-13"
            :max-height="contentHeight"
        >
            <v-container 
                :min-height="contentHeight"
                :max-height="contentHeight"
            >
                <slot />
            </v-container>
        </v-sheet>
    </div>
</template>
<script>
    export default {
        name: 'AppBar',
        components: {
            
        },
        props: {
        },
        metaInfo() {
            return {
                changed: meta => (this.title = meta.titleChunk.toUpperCase()),
            }
        },
        mounted() {
            this.offset = this.$refs.appBar.$el.clientHeight;
        },
        computed: {
            contentHeight () {
                return this.windowHeight - this.offset;
            },
        },
        data: () => ({
            title: '',
            offset: 0,
            windowHeight: 0
        }),
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            handleResize() {
                this.windowHeight = window.innerHeight;
            }
        }
    }
</script>

<style scoped>
.v-app-bar {
    border-bottom: 1px solid gainsboro !important;
}
</style>