<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <process-counter 
          :current="processCounter.current"
          :limit="processCounter.limit"
          :total="processCounter.total"
        />
      </v-col>
      <v-col cols="6">
        <pending-tasks
          :pendingProcesses="tasks.pendingProcesses"
          :pendingPrints="tasks.pendingPrints"
        />
      </v-col>
      <v-col cols="12">
        <last-news />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProcessCounter from '@/components/app/widgets/ProcessCounter'
import PendingTasks from '@/components/app/widgets/PendingTasks'
import LastNews from '@/components/app/widgets/LastNews'

export default {
    name: 'Dashboard',

    components: {
      ProcessCounter,
      PendingTasks,
      LastNews,
    },

    metaInfo: {
        title: 'Panell',
    },

    data: () => ({
      processCounter: {
        current: 3,
        limit:100,
        total:15
      },
      tasks: {
        pendingProcesses: 1,
        pendingPrints: 0
      },
    }),
}
</script>