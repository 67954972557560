import Vue from "vue";
import JwtClient from "./jwtClient.js";
import VueJwtDecode from 'vue-jwt-decode';

let instance;

export const getInstance = () => instance;

export const useJwtClient = () => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: null,
        auth: null,
        jwt: null,
        error: null
      };
    },
    methods: {
      setJwt(jwt) {
        this.jwt = jwt;
        localStorage.setItem('jwt', this.jwt);
        
        this.setUserFromStoredToken();
      },
      /** Handles the callback when logging in using a redirect */
      signIn(email, password) {
        this.loading = true;
        return new Promise((resolve, reject) => {
          JwtClient.signIn({email: email, password: password})
          .then( response => {
            this.setJwt(response.data.jwt);
            resolve(response);
          })
          .catch( err => {
            this.cleanAuthData();
            reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
        });
      },
      signUp(payload) {
        return new Promise((resolve, reject) => {
          JwtClient.signUp(payload)
          .then( response => {
            this.setJwt(response.data.jwt);
            resolve(response);
          })
          .catch( err => {
            this.cleanAuthData();
            reject(err);
          });
        });
      },
      /** Logs the user out and removes their session on the authorization server */
      logout() {
        this.cleanAuthData();
      },
      setAuthData() {
        this.jwt = localStorage.getItem('jwt');
        this.auth = VueJwtDecode.decode(this.jwt);
        this.user = this.auth.data;
        this.isAuthenticated = true;
      },
      cleanAuthData() {
        localStorage.removeItem('jwt');
        this.jwt = null;
        this.auth = null;
        this.user = null;
        this.isAuthenticated = false;
      },
      setUserFromStoredToken() {
        if (localStorage.getItem('jwt') === null) return;

        try {
          this.setAuthData();
        } catch (e) {
          this.cleanAuthData();
        }
      },
      getJwt() {
        return this.jwt;
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      this.setUserFromStoredToken();
      this.loading = false;
    }
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const JwtAuthPlugin = {
  install(Vue) {
    Vue.prototype.$auth = useJwtClient();
  }
};