<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  import i18n from '@/lang/lang.js'

  let LOGO_PATH = 'https://tactilens.com/logo_light.png'
  let TITLE = i18n.t('app')
  let DESCRIPTION = i18n.t('description')

  export default {
    name: 'App',
    metaInfo: {
      title: 'Tactilens',
      titleTemplate: '%s | Tactilens',
      htmlAttrs: { lang: i18n.locale },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },

        // Twitter Card
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: TITLE},
        {name: 'twitter:description', content: DESCRIPTION},
        // image must be an absolute path
        {name: 'twitter:image', content: LOGO_PATH},
        // Facebook OpenGraph
        {property: 'og:url', content: 'https://tactilens.com/'},
        {property: 'og:app_id', content: ''},
        {property: 'og:title', content: TITLE},
        {property: 'og:site_name', content: TITLE},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  LOGO_PATH},
        {property: 'og:description', content: DESCRIPTION}
      ]
    }
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
