<template>
  <div>
    <v-card
      :height="height"
      :width="width"
      outlined
      tile
      v-bind="$attrs"
    >
      <v-container 
        class="pa-0"
        fluid
      >
        <v-row no-gutters>
          <v-col md="6"
            v-for="(image,i) in images"
            :key="i"
          >
            <v-img
            :src="image"
            :height="imageHeight"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-card-title class="text-button">{{title}}</v-card-title>
      <v-card-subtitle v-if="subtitle">{{subtitle}}</v-card-subtitle>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'BaseImgAndModel',

    inheritAttrs: false,

    methods: {
    },

    data() {
      return {
      };
    },

    props: {
      images: Array,
      imageHeight: [Number, String],
      height: [Number, String],
      width: [Number, String],
      subtitle: String,
      title: String,
    },
  }
</script>
<style lang="css" scoped>
  .v-window__container--is-active{
    height: 100% !important;
  }
  .dialog-carousel, .dialog-carousel .v-window__container, .cara .v-carousel__item { 
    height: 100%!important; 
  }

</style>