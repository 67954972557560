<template>
    <container class="mx-auto overflow-hidden">
        
            <v-container 
                class="pa-0 process-summary-container"
                fluid
                @click="goToDetails(process.id)"
            >
                <v-row no-gutters>
                    <v-col md="12">
                        <v-img
                            v-if="process.file && !showOriginal"
                            :src="process.file.thumbnail"
                            :height="imageHeight"
                        />
                        <v-img
                            v-if="process.file && showOriginal"
                            :src="process.file.original"
                            :height="imageHeight"
                        />
                        <v-chip 
                            small
                            :color="processColor"
                            class="status-chip"
                        >
                            {{this.$i18n.t('components.processSummary.' + process.status)}}
                        </v-chip>
                    </v-col>
                </v-row>
                <v-row align="center" class="px-3 grey--text text--darken-1">
                    <v-col cols="8">
                        <h2 class="title">
                            {{process.file.name}}
                        </h2>
                    </v-col>
                    <v-col cols="4" class="pa-4 text-right">
                        {{new Date(process.createdAt).toLocaleString()}}
                    </v-col>
                </v-row>
            </v-container>

    </container>
</template>
<script>
import Container from '@/components/app/Container'
import router from '@/router'

export default {
    name: 'ProcessSummary',
    components: {
        Container
    },
    computed: {
      processColor: function() {
        switch (this.process.status) {
            case 'pending':
            case 'processing':
                return 'orange';
            case 'processed':
                return 'primary';
            case 'accepted':
                return 'green';
            case 'rejected':
                return 'red';
            default:
                return '';
        }
      },
    },
    methods: {
        goToDetails(id) {
            router.push({ name: 'details', params: { id: id }});
        }
    },
    props: {
        showOriginal: {
            type: Boolean,
            default: false
        },
        imageHeight: {
            type: Number,
            default: 400
        },
        process: {
            type: Object,
            default: () => ({
                id: null,
                file: null,
                status: null,
                createdAt: null,
            })
        }
    }
}
</script>
<style scoped>
    .process-summary-container{
        cursor: pointer;
    }

    .status-chip {
        position: absolute;
        right: 10px;
        top: 10px;
    }

</style>
