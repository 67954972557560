<template>
  <container>
    <v-card-text>
      <div class="title font-weight-bold">{{title}}</div>
      <div class="subheading font-weight-light grey--text" v-if="subtitle">{{subtitle}}</div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </container>
</template>
<script>
import Container from '@/components/app/Container'

export default {
  name: 'Widget',
  components: {
    Container
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  data: () => ({
  }),
}
</script>
<style scoped>
</style>

