<template>
  <widget
    title="Última hora"
  >
    <v-container>
      <v-row no-gutters v-for="(item,key) in news" :key="key">
        <v-col cols="3">{{new Date(item.date).toLocaleDateString()}}</v-col>
        <v-col cols="9">{{item.description}}</v-col>
      </v-row>
    </v-container>
  </widget>
</template>
<script>
import Widget from '@/components/app/widgets/Widget';

export default {
  name: 'LastNews',
  components: {
    Widget
  },
  props: {
  },
  data: () => ({
    news: [
      {
        date: '2020-07-12',
        description: "Bones notícies! Avui obrim Tactilens per a tothom!"
      },
      {
        date: '2020-07-01',
        description: "Obrim l'aplicació en versió beta privada."
      },
      {
        date: '2020-06-01',
        description: "Primeres proves de Tactilens."
      }
    ]
  }),
}
</script>
<style scoped>
</style>

