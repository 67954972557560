<template>
  <container>
    <v-card-title class="headline">{{ $auth.user.name }}</v-card-title>
    <v-card-subtitle class="subtitle">{{ $auth.user.email }}</v-card-subtitle>

    <v-divider></v-divider>
    
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.name"
                :rules="nameRules"
                label="Nom"
                hide-details=false
                required
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.surname"
                :rules="nameRules"
                label="Cognoms"
                hide-details=false
                required
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                label="E-mail"
                hide-details=false
                required
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="user.language"
                :items="languages"
                label="Idioma"
                hide-details=false
                outlined
                @change="changeLanguage"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn 
                class="primary"
                depressed
                large
                @click="updateUser"
                :loading="btn.saveUser"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </container>
</template>

<script>
import i18n from '@/lang/lang'
import Container from '@/components/app/Container'

export default {
    name: 'PersonalDetails',
    
    components: {
      Container
    },

    methods: {
      changeLanguage(key) {
        i18n.locale = key;
        this.language = key;
      },
      updateUser() {
        this.btn.saveUser = true;
        const payload = {
          name: this.user.name,
          surname: this.user.surname,
          email: this.user.email,
          language: this.user.language,
        };

        this.api.updateUser(payload)
        .then(result => {
          this.user = result.data;
          this.btn.saveUser = false;
        });
      }
    },
    async created() {
      this.api.getUser()
      .then(result => {
        this.user = result.data;
      });
    },
    data: () => ({
      btn: {
        saveUser: false,
      },
      user: {
        id: null,
        name: null,
        surname: null,
        email: null,
        language: null,
        status: null
      },
      valid: false,
      languages: [
        { value: 'ca', text: 'Català' },
        { value: 'es', text: 'Español' },
        { value: 'en', text: 'English' }
      ],
      nameRules: [
      ],
      emailRules: [
        v => /.+@.+/.test(v) || "Has d'introduir un email valid",
      ],
    })
}
</script>