<template>
  <v-container v-if="process">
    <v-row>
      <v-col>
        <process-summary 
          :process="process"
          :imageHeight="imageHeight"
          :showOriginal="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <model-render
          v-if="process.model"
          :src="process.model"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <printing-tips />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProcessSummary from '@/components/app/ProcessSummary'
import PrintingTips from '@/components/app/PrintingTips'
import ModelRender from '@/components/app/ModelRender'

export default {
    name: 'ProcessDetails',
    components: {
      ProcessSummary,
      PrintingTips,
      ModelRender,
    },

    metaInfo: {
        title: 'Detalls',
    },

    data: () => ({
      process: null,
      imageHeight: 400
    }),

    mounted(){

    },

    methods: {
      loadDetails() {
        if (!this.$route.params.id){
          this.$router.push({name:"app"});
        }

        this.api.getConversion(this.$route.params.id)
        .then(resource => {
          this.process = resource.data;
          if (this.process.model === null){
            setTimeout(() => {
              if (this.$route.name === 'details'){
                this.loadDetails();
              }
            }, 5000);
          }
        });
      }
    },

    async created() {
      this.loadDetails();
    }
}
</script>
<style scoped>
</style>