<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step :complete="step > 1" step="1">
      Selecciona la imatge
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-container>
        <file-uploader 
          v-on:send="startProcess"
          :key="ref"
        />
      </v-container>
    </v-stepper-content>
  
    <v-stepper-step :complete="step > 2" step="2">
      Pujant la imatge
    </v-stepper-step>

    <v-stepper-content
      step="2" 
      class="text-center"
    >
      <v-container>
        <container 
          class="pb-10 pt-5"
        >
          <v-progress-circular
            :size="100"
            :width="7"
            color="indigo"
            indeterminate
            class="mt-10 mb-5"
          />
          <p class="text-label">S'està pujant i validant la teva imatge. No tanquis el navegador o el procés s'anul·larà.</p>
        </container>
      </v-container>
    </v-stepper-content>
  
    <v-stepper-step :complete="step > 3" step="3">
      Generació del model
    </v-stepper-step>

    <v-stepper-content 
      step="3"
      class="text-center"
    >
      <v-container>
        <container 
          class="pa-16"
        >
          <p class="text-label">Estem treballant amb la teva imatge per generar-ne el model 3D. Quan estigui llest t'avisarem per email.</p>
        </container>
      </v-container>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import Container from '@/components/app/Container'
import FileUploader from '@/components/app/FileUploader'

export default {
    name: 'Process',
    components: {
      Container,
      FileUploader
    },

    metaInfo: {
        title: 'Conversor 2D-3D',
    },

    data: () => ({
      file: null,
      process: null,
      step: 1,
      ref: 1
    }),
    
    mounted(){
      this.resetProcess();
    },

    methods: {
      resetProcess() {
        this.step = 1;
        this.ref++;
        this.file = null;
        this.process = null;
      },
      startProcess(file) {
        this.file = file;
        
        const payload = {
          filename: this.file.name,
          content: this.file.base64
        };

        window.setTimeout(() => {
            this.step = 2;
            this.api.createConversion(payload)
            .then(result => {
              this.process = result;
              this.step = 3;
            });
        }, 1500);
      }
    }
}
</script>
<style scoped>
  .v-stepper {
    box-shadow: none;
  }
  .text-label {
    color: grey !important;
  }
</style>