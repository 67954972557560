import { getInstance } from "./index";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const checkAuth = () => {
    if (to.name !== 'login' && !authService.isAuthenticated) {
      next({ name: 'login' });
    } else {
      next();
    }
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return checkAuth();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return checkAuth();
    }
  });
};