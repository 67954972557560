<template>
  <v-card
    :height="height"
  >
    <v-card-title>{{title}}</v-card-title>
    <v-card-subtitle>{{subtitle}}</v-card-subtitle>
    <v-card-text class="text--primary text-left">
      <v-list>
        <v-list-item 
          v-for="(line,i) in lines"
          v-bind:key="i"
        >
          {{line}}
        </v-list-item>
      </v-list>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'BasePricingPlan',

    inheritAttrs: true,
    
    props: {
      height: [String, Number],
      dark: Boolean,
      lines: Array,
      title: String,
      subtitle: String,
    },
  }
</script>
