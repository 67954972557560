<template>
  <v-container cols="12">
    <v-row>
      <v-col
        lg="6"
        offset-lg="0"
        md="10"
        offset-md="1"
        v-for="(process,id) in history"
        :key="id"
      >
        <process-summary 
          :process="process"
          :imageHeight="height"
        />
      </v-col>
    </v-row>

    <!--
    <div class="text-center">
      <v-pagination
        v-model="pagination.current"
        :length="pagination.length"
      ></v-pagination>
    </div>-->
  </v-container>
</template>

<script>
import ProcessSummary from '@/components/app/ProcessSummary'

export default {
    name: 'History',
    components: {
      ProcessSummary
    },

    metaInfo: {
        title: 'Historial',
    },

    data: () => ({
      height: 200,
      pagination: {
        current: 1,
        length: 2,
        itemsPerPage: 2
      },
      history: []
    }),

    async created() {
      this.api.getConversions()
      .then(resources => {
        this.history = resources.data;
      });
    }
}
</script>