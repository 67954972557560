import Vue from 'vue';
import axios from "axios";

let instance;

const parseCall = (promise) => {
  return new Promise((resolve, reject) => {
    promise.then( response => {
      resolve(response.data);
    })
    .catch( error => {
      console.log(error);
      Vue.prototype.$notify({
        type: "error",
        duration: 10000,
        text: error.message
      });
      reject(error);
    });
  });
};
const getConnection = () => {
  const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/`;
  const header = {
    'Authorization': 'Bearer ' + Vue.prototype.$auth.getJwt(),
  };

  return axios.create({
    baseURL: baseUrl,
    headers: header
  });  
};

const getClient = () => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        connection: null,
        baseUrl: null,
        header: null
      };
    },
    methods: {
      health() {
        return parseCall(getConnection().get('/health'));
      },
      getConversion(id) {
        return parseCall(getConnection().get(`/conversion/${id}`));
      },
      getConversions() {
        return parseCall(getConnection().get('/conversion'));
      },
      createConversion(payload) {
        return parseCall(getConnection().post('/conversion', payload));
      },
      getUser() {
        return parseCall(getConnection().get('/user'));
      },
      updateUser(payload) {
        return parseCall(getConnection().put('/user', payload));
      },
    }
  });

  return instance;
};

export const Api = {
  install(Vue) {
    Vue.prototype.api = getClient();
  }
};