<template>
  <v-container>
    <v-row>
      <v-col>
        <personal-details />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <plan-selector />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PersonalDetails from '@/components/app/PersonalDetails'
import PlanSelector from '@/components/app/PlanSelector'

export default {
    name: 'Account',
    components: {
      PersonalDetails,
      PlanSelector
    },

    metaInfo: {
        title: 'El meu compte',
    },

    methods: {
    },

    data: () => ({
      
    })
}
</script>