<template>
    <v-navigation-drawer
        app
        class="left-menu"
        :permanent="permanent"
    >
        <v-list>
            <v-list-item two-line class="px-10 pa-7">
                <v-img class="logo" width="70%" alt="Tactilens logo" src="@/assets/logo_light.png"></v-img>
            </v-list-item>
            
            <v-list-item two-line class="px-2">
                <v-list-item-content>
                    <v-container class="pa-0">
                        <v-row no-gutters>
                            <v-col cols="12" class="text-center">
                                <v-chip 
                                    small
                                    :color="chip.color"
                                >
                                    {{ chip.status }}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" class="text-center">
                                <span class="ma-0 text-h6">
                                    {{ $auth.user.name }} {{ $auth.user.surname }}
                                </span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <p class="ma-0 text-caption text-center">{{ $auth.user.email }}</p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-list-item-content>

            </v-list-item>
        
            <v-divider></v-divider>
        
            <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="{name: item.route}"
            >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            
            <v-container class="pull-bottom ma-0 pa-0">
                <v-divider></v-divider>
                <v-list-item :href="domain">
                    <v-list-item-icon>
                        <v-icon>mdi-web</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{this.$i18n.t('components.leftMenu.website')}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                
                <v-list-item @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{this.$i18n.t('components.leftMenu.logout')}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-container>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
export default {
    name: "left-menu",
    components: {

    },
    methods: {
        // Log the user out
        logout() {
            this.$auth.logout();
            this.$router.push('/');
        }
    },
    props: {
    },
    data() {
        return {
            domain: "https://tactilens.com",
            chip: {
                color: (this.$auth.user.status === 'confirmed') ? 'primary': 'secondary',
                status: this.$i18n.t('components.leftMenu.statuses.'+this.$auth.user.status)
            },
            permanent: true,
            items:[
                //ICONS: https://cdn.materialdesignicons.com/5.1.45/
                {title: this.$i18n.t('components.leftMenu.dashboard'), icon: 'mdi-view-dashboard', route: 'dashboard'},
                {title: this.$i18n.t('components.leftMenu.process'), icon:'mdi-image-plus', route: 'process'},
                {title: this.$i18n.t('components.leftMenu.history'), icon:'mdi-history', route: 'history'},
                {title: this.$i18n.t('components.leftMenu.account'), icon:'mdi-home-account', route: 'account'},
            ],
        };
    },
}
</script>
<style scoped>

.pull-bottom {
    position: absolute;
    bottom: 0;
}
.v-list-item--active {
    color: #5c6bc0;
}
</style>