<template>
  <container>
    <vue-base64-file-upload 
        accept="image/png,image/jpeg"
        image-class="image-input"
        input-class="image-input"
        :placeholder="inputText"
        :max-size="customImageMaxSize"
        @size-exceeded="onSizeExceeded"
        @file="onFile"
        @load="onLoad" />
  </container>
</template>
<script>
  import Container from '@/components/app/Container'
  import VueBase64FileUpload from 'vue-base64-file-upload';

  export default {
    name: 'FileUploader',
    components: {
      Container,
      VueBase64FileUpload
    },
    data: () => ({
      inputText: "Cliqueu aquí per pujar la imatge",
      file: null,
      customImageMaxSize: 10 // megabytes
    }),
    methods: {
      onFile(file) {
        this.file = file;
      },
      onLoad(dataUri) {
        this.file.base64 = dataUri;
        this.$emit("send", this.file);
      },
      onSizeExceeded(size){
        this.$notify({
          type: "error",
          duration: 10000,
          text: `Image size (${size}MB) exceeds limit of ${this.customImageMaxSize}MB.`
        });
      }
    },
    props: {
      dragover: {
        type: Boolean,
        default: false
      }
    }
  }

</script>
<style>
.vue-base64-file-upload {
  border: none !important;
  cursor: pointer;
  text-align: center;
  padding: 50px 0px;
}
.vue-base64-file-upload * {
  text-align: center;
}
.vue-base64-file-upload > div {
  margin: 40px 0;
}
.vue-base64-file-upload > .image-input {
  margin: auto;
  width: auto !important;
  height: 200px !important;
}
</style>