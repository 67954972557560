<template>
  <widget
    title="Tasques pendents"
  >
    <v-container>
      <v-row no-gutters>
        <v-col cols="9" class="text-subtitle-1">Conversions en curs:</v-col>
        <v-col cols="3" class="text-center headline primary--text">{{pendingProcesses}}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="9" class="text-subtitle-1">Impressions pendents:</v-col>
        <v-col cols="3" class="text-center headline primary--text">{{pendingPrints}}</v-col>
      </v-row>
    </v-container>
  </widget>
</template>
<script>
import Widget from '@/components/app/widgets/Widget';

export default {
  name: 'PendingTasks',
  components: {
    Widget
  },
  props: {
    pendingProcesses: {
      type: Number
    },
    pendingPrints: {
      type: Number
    }
  },
  data: () => ({
  }),
}
</script>
<style scoped>
</style>

