import Vue from 'vue'
import VueRouter from 'vue-router'

import Logged from '../views/base/Logged.vue'

import Account from '../views/app/Account.vue'
import Dashboard from '../views/app/Dashboard.vue'
import History from '../views/app/History.vue'
import Process from '../views/app/Process.vue'
import ProcessDetails from '../views/app/ProcessDetails.vue'

import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/public/login/Index.vue')
  }, {
    path: '/app',
    name: 'app',
    component: Logged,
    redirect: '/app/dashboard',
    beforeEnter: authGuard,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      }, {
        path: "process",
        name: "process",
        component: Process
      }, {
        path: "details/:id",
        name: "details",
        component: ProcessDetails
      }, {
        path: "history",
        name: "history",
        component: History
      }, {
        path: "account",
        name: "account",
        component: Account
      }, {
        path: '*',
        name: 'FourOhFour',
        component: () => import('@/views/public/404/Index.vue'),
      }
    ]
  }, {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router