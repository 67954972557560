<template>
    <container
        class="model-render pa-0 overflow-hidden"
        :v-if="rendered"
    >
        <v-card
            rounded
            outlined
            class="instructions"
        >
            <v-card-title class="title">Controls</v-card-title>
            <v-card-subtitle>
                <v-icon>mdi-arrow-all</v-icon> Drag the model to rotate it <br/>
                <v-icon>mdi-arrow-up-down-bold-outline</v-icon> Scroll to zoom in/out
            </v-card-subtitle>
        </v-card>

        <model-obj
            class="model-object pa-0"
            :rotation="rotation"
            :position="position"
            :lights="lights"
            :src="src"
            :background-color="bgColor"
            @on-progress="onProgress"
        ></model-obj>
    </container>
</template>
<script>
    import Container from '@/components/app/Container';
    import { ModelObj } from 'vue-3d-model';

    export default {
        name: 'ModelRender',
        components: {
            Container,
            ModelObj
        },
        data() {
            return {
                bgColor: "#EEE",
                rotation: {
                    x: Math.PI / 6,
                    y: 0,
                    z: 0
                },
                position: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                lights: [{
                    type: 'HemisphereLight',
                    position: { x: 0, y: 0, z: 1000 },
                    skyColor: 0xffffff,
                    groundColor: 0xdddddd,
                    intensity: 0.5,
                },/*{
                    type: 'HemisphereLight',
                    position: { x: 0, y: 0, z: -1000 },
                    skyColor: 0xdddddd,
                    groundColor: 0x333333,
                    intensity: 0.2,
                },*/{
                    type: 'DirectionalLight',
                    position: { x: 0, y: 10000, z: 0 },
                    color: 0xDEDEDE,
                    intensity: 0.4,
                },{
                    type: 'DirectionalLight',
                    position: { x: 0, y: -10000, z: 0 },
                    color: 0xDEDEDE,
                    intensity: 0.4,
                }],
                rendered: false,
                autoRotate: false
            }
        },
        props: {
            src: {
                type: String,
                default: null
            }
        },
        methods: {
            onProgress() {
                this.rendered = true;
            },
            rotate () {
                if (this.autoRotate){
                    this.rotation.y += 0.01;
                    requestAnimationFrame( this.rotate );
                }
            }
        }
    }
</script>

<style scoped>
.model-render{
    background-color: #EEE;
    position: relative;
}
.model-object {
    cursor: move;
}
.instructions {
    position:absolute;
    top: 1rem;
    left: 1rem;
}
</style>