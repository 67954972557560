<template>
  <v-card
    class="hidden-overflow"
  >
    <slot></slot>
  </v-card>
</template>
<script>
    export default {
      name: 'Container',
    }
</script>
<style scoped>
</style>

