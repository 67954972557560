import axios from "axios";

const resource = `${process.env.VUE_APP_API_BASE_URL}/auth`;

const connection = axios.create({
    baseUrl: resource
});

export default {
    signUp(payload) {
        return connection.post(`${resource}/signup`, payload);
    },
    signIn(payload) {
        return connection.post(`${resource}/signin`, payload);
    }
};