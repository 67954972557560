<template>
  <container>
    <v-card-title class="headline">Canvia el teu pla</v-card-title>

    <v-divider></v-divider>
    
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="headline font-weight-bold text-left"></th>
              <th class="headline font-weight-bold text-center" v-for="plan in plans" :key="'sm_name_'+plan.key">{{plan.name}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-subtitle-2 font-weight-bold">{{columnHeaders[1]}}</td>
              <td
                class="text-center" 
                v-for="plan in plans" :key="'sm_limit_'+plan.key"
              >
                {{ plan.limit.value }}<span v-if="plan.limit.units">/{{ plan.limit.units }}</span>
              </td>
            </tr>
            <tr>
              <td class="text-subtitle-2 font-weight-bold text-left">{{columnHeaders[2]}}</td>
              <td
                class="text-center" 
                v-for="plan in plans" :key="'sm_simple_'+plan.key"
              >
                <v-icon v-if="plan.simple" color="green">mdi-checkbox-marked-circle-outline</v-icon>
              </td>
            </tr>
            <tr>
              <td class="text-subtitle-2 font-weight-bold text-left">{{columnHeaders[3]}}</td>
              <td
                class="text-center" 
                v-for="plan in plans" :key="'sm_advanced_'+plan.key"
              >
                <v-icon v-if="plan.advanced" color="green">mdi-checkbox-marked-circle-outline</v-icon>
              </td>
            </tr>
            <tr>
              <td class="text-subtitle-2 font-weight-bold text-left">{{columnHeaders[4]}}</td>
              <td
                class="text-center" 
                v-for="plan in plans" :key="'sm_discount_'+plan.key"
              >
                {{ plan.discount }}%
              </td>
            </tr>
            <tr>
              <td class="text-subtitle-2 font-weight-bold text-left">{{columnHeaders[5]}}</td>
              <td 
                class="text-center primary--text font-weight-bold" 
                v-for="plan in plans" :key="'sm_price_'+plan.key"
              >
                {{ plan.price }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td
                class="text-center" 
                v-for="plan in plans" :key="'sm_action_'+plan.key"
              >
                <v-btn 
                  v-if="!plan.owned"
                  class="primary"
                  depressed
                  @click="changePlan(plan.key)"
                >
                  Canviar
                </v-btn>
                <span v-if="plan.owned">
                  Contractat
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </container>
</template>

<script>
import Container from '@/components/app/Container'
export default {
  name: 'PlanSelector',
  
  components: {
    Container
  },

  methods: {
  },

  data: () => ({
    selectedPlan: 'free',
    columnHeaders: [
      "Tipus de pla",
      "Límit de conversions",
      "Conversor simple",
      "Conversor avançat",
      "Dte. impressions",
      "Preu"
    ],
    plans: [
      { 
        name: 'Free',
        key: 'free',
        limit: {
          value: 3,
          units: ''
        },
        simple: true,
        advanced: false,
        discount: 0,
        price: 'Gratis',
        owned: true
      },
      { 
        name: 'Lite',
        key: 'lite',
        limit: {
          value: 10,
          units: 'mes'
        },
        simple: true,
        advanced: false,
        discount: 0,
        price: '5€/mes',
        owned: false
      },
      {
        name: 'Plus',
        key: 'plus',
        limit: {
          value: 100,
          units: 'mes'
        },
        simple: true,
        advanced: true,
        discount: 5,
        price: '15€/mes',
        owned: false
      },
      { 
        name: "Premium",
        key: 'premium',
        limit: {
          value: 200,
          units: 'mes'
        },
        simple: true,
        advanced: true,
        discount: 10,
        price: '25€/mes',
        owned: false
      },
    ]
  })
}
</script>