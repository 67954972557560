<template>
    <v-app>
        <left-menu
          v-if="$auth.isAuthenticated"
        />

        <!-- Sizes your content based upon application components -->
        <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
            <app-bar>
              <v-container>
                <v-row>
                  <v-col
                    offset-xl="2"
                    xl="8"
                    offset-lg="1"
                    lg="10"
                    md="12"
                  >
                    <notifications class="mr-4 mt-10">
                      <!--<template slot="body" slot-scope="props">
                        <v-alert
                          :type="props.item.type"
                          elevation="2"
                          class="ma-0 mt-10"
                        >
                          {{props.item.text}}
                        </v-alert>
                      </template>-->
                    </notifications>
                    <router-view />
                  </v-col>
                </v-row>
              </v-container>
            </app-bar>
        </v-container>
        </v-main>

        <!--<v-footer 
        dark
        padless
        app>
        <v-card-text class="py-2 white--text text-right">
            Desenvolupat amb <span style="color:red">♡</span> per
            <a href="http://jepihumet.com" target="new" class="py-2 white--text" style="text-decoration:none; font-weight:bold">Jepi Humet</a>
            
        </v-card-text>
        </v-footer>-->
    </v-app>
</template>
<script>
import LeftMenu from '@/components/app/LeftMenu'
import AppBar from '@/components/app/AppBar'

export default {
  name: 'Logged',
  components: {
    LeftMenu,
    AppBar
  },
  data: () => ({
  })
}
</script>

<style lang="scss">
.notifications{
 }
</style>