<template>
  <widget
    title="Conversions realitzades"
  >
    <v-container>
      <v-row no-gutters>
        <v-col cols="7" class="text-subtitle-1">Total històric:</v-col>
        <v-col cols="5" class="text-center headline primary--text">{{total}}</v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="7" class="text-subtitle-1">Aquest mes:</v-col>
        <v-col cols="5" class="text-center headline primary--text">{{current}}/{{limit}}</v-col>
      </v-row>
    </v-container>
  </widget>
</template>
<script>
import Widget from '@/components/app/widgets/Widget';

export default {
  name: 'ProcessCounter',
  components: {
    Widget
  },
  props: {
    current: {
      type: Number
    },
    limit: {
      type: Number
    },
    total: {
      type: Number
    }
  },
  data: () => ({
  }),
}
</script>
<style scoped>
</style>

