import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import i18n from './lang/lang';
import Notifications from 'vue-notification';

import configPlugin from './config';
Vue.use(configPlugin);

import { JwtAuthPlugin } from "./auth";
Vue.use(JwtAuthPlugin);

import { Api } from "./api";
Vue.use(Api);

import './plugins';

Vue.config.productionTip = false;
Vue.use(Notifications);

new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App)
}).$mount("#app");

Vue.prototype._i18n = i18n;