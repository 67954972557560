import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const languages = ['ca','es','en']

const fallbackLocale = 'ca'

const navLang = navigator.language || navigator.userLanguage
const locale = navLang ? navLang.split('-')[0] : fallbackLocale;

var messages = {};
languages.forEach(lang => {
    messages[lang] = require("./locales/" + lang + ".json")
});

const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: fallbackLocale,
    messages
})

export default i18n